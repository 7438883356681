import { yupResolver } from '@hookform/resolvers/yup';
import { DateBox } from 'devextreme-react/date-box';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { BaseForm, CheckBoxList, ComboBox, LoadingPanel, SwitchBox, TextAreaBox, TextBox } from '../../../components/base';
import { ImageBox } from '../../../components/base/ImageBox';
import TextEditor from '../../../components/base/TextEditor';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ComboBoxArticleCategory } from '../../../components/shared/hoc-article-category/ComboBoxArticleCategory';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { ArticleActionConfirmRequest } from '../../../context/models/articles/ArticleActionConfirmRequest';
import { ArticleModel, dfArticleModel, validArticleSchema } from '../../../context/models/articles/ArticleModel';
import { eArticleArchivesData } from '../../../context/models/articles/eArticleArchives';
import { eArticleGenresData } from '../../../context/models/articles/eArticleGenres';
import { getArticleStatusName } from '../../../context/models/articles/eArticleStatus';
import { ArticleActionTypes } from '../../../context/stores/articles/IArticleActionsTypes';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { slugHelper } from '../../../utils/slugHelper';
import { ArticleButton } from '../button-actions/ArticleButton';
import { ChangeStatusConfirm } from '../change-status-confirm';
import { RelatedArticles } from '../related-articles';
import { history } from '../../../utils/history';

const mapStateToProps = (state: AppState) => {
	return {
		status: state.article.status,
		detailResponse: state.article.detailResponse,
		selectedIds: state.article.selectedIds,
		showConfirm: state.article.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ArticleActionTypes>) => ({
	handleGetDetail: (id: string) => {
		dispatch(actions.article.detailRequest(id));
	},
	handleSaveData: (data: ArticleModel) => {
		dispatch(actions.article.saveRequest(data));
	},
	handleChangeStatus: (detail: ArticleActionConfirmRequest) => {
		dispatch(
			actions.article.changeStatusRequest({
				actionStatus: detail.actionStatus,
				articleIds: detail.ids || [],
				receivedBy: detail.receivedBy || '',
				message: detail.message || '',
				subject: detail.subject || '',
			})
		);
	},
	onShowConfirm: (detail: ArticleActionConfirmRequest) => {
		dispatch(actions.article.showConfirm(detail));
	},
});

type IArticleEditPageProps = {
	status: eBaseActionStatus;
	detailResponse?: ArticleModel;
	selectedIds: string[];
	showConfirm: ArticleActionConfirmRequest;
	handleGetDetail: (id: string) => void;
	handleSaveData: (data: ArticleModel) => void;
	handleChangeStatus: (data: ArticleActionConfirmRequest) => void;
	onShowConfirm: (data: ArticleActionConfirmRequest) => void;
};

const ArticleEditPage = (props: IArticleEditPageProps) => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { detailResponse, status, showConfirm, selectedIds, handleGetDetail, handleChangeStatus, handleSaveData, onShowConfirm } = props;

	const [initialValues, setInitialValues] = useState<ArticleModel>(dfArticleModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ArticleModel>({
		resolver: yupResolver(validArticleSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ArticleModel) => {
		handleSaveData({ ...values });
	};

	const watchChangeName = useWatch({ control, name: 'title', defaultValue: '' });

	useEffect(() => {
		const slug = slugHelper.toSlug(watchChangeName);
		setValue('titleSlug', slug);
		setInitialValues((prev) => ({ ...prev, titleSlug: slug }));
	}, [watchChangeName, setValue]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (detailResponse) {
			setInitialValues((prev) => ({ ...prev, ...detailResponse, publishedOnDate: moment(detailResponse.publishedOnDate).format() }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailResponse]);

	useEffect(() => {
		if (id && id !== '0') {
			handleGetDetail(id);
		} else {
			setInitialValues(dfArticleModel);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) navigate(history.location?.pathname || '/', { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Viết nội dung Tin bài`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<ArticleButton
								isOwner={true}
								articleStatus={initialValues.status}
								isActionDisable={true}
								onActionConfirm={(actionChange) => {
									onShowConfirm({ isShow: true, actionStatus: actionChange, ids: [] });
									setInitialValues((prev) => ({ ...prev, actionStatus: actionChange }));
								}}
								handleSubmit={handleSubmit(onSubmitHandler)}
								isSaving={status === eBaseActionStatus.saving}
							/>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingPanel />}
						<BaseForm
							onSubmit={(e) => {
								handleSubmit(onSubmitHandler);
							}}
						>
							<div className='row g-4'>
								<div className='col-xl-9 col-sm-12'>
									<div className='nk-block'>
										<div className='row g-4'>
											<div className='col-lg-8'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='title'>Tiêu đề</h6>
												</div>
												<TextAreaBox
													name='title'
													value={initialValues.title}
													error={errors.title?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, title: value }));
													}}
													className='form-control-simple no-resize editor-focus editor-f-22'
													classWrapper='border-bottom'
													sizeClass='sm'
													placeholder='Viết gì đó...'
												/>
											</div>
											<div className='col-lg-4'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='titleSlug'>Ảnh bìa</h6>
												</div>
												<ImageBox
													name='image'
													value={initialValues.image}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, image: value || '' }));
													}}
												/>
											</div>
										</div>
									</div>
									<div className='nk-block'>
										<div className='row g-4'>
											<div className='col-lg-8'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='titleSlug'>Slug</h6>
												</div>
												<TextBox
													name='titleSlug'
													value={initialValues.titleSlug}
													error={errors.titleSlug?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, titleSlug: value }));
													}}
													sizeClass='sm'
													className='form-control-simple editor-focus  editor-f-16'
													classWrapper='border-bottom'
													helpBlock='Link hiển thị: tieu-de-tin-bai'
												/>
											</div>
											<div className='col-lg-4'>
												<div className='nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='catId'>Chuyên mục</h6>
												</div>
												<ComboBoxArticleCategory
													name='catId'
													value={initialValues.catId}
													error={errors.catId?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, catId: value }));
													}}
												/>
											</div>
										</div>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='initContent'>Sapo</h6>
										</div>
										<TextAreaBox
											name='initContent'
											value={initialValues.initContent}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, initContent: value }));
											}}
											className='form-control-simple no-resize editor-focus editor-f-18'
											classWrapper='border-bottom'
											sizeClass='sm'
											placeholder='Viết gì đó...'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='contentHtml'>Nội dung</h6>
										</div>
										<TextEditor
											name='contentHtml'
											value={initialValues.contentHtml}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, contentHtml: value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='relatedIds'>Tin liên quan</h6>
										</div>
										<RelatedArticles />
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='relatedProductIds'>Sản phẩm liên quan</h6>
										</div>
										<RelatedArticles />
									</div>
								</div>
								<div className='col-xl-3'>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Ngày xuất bản</h6>
										</div>
										<DateBox
											name='publishedOnDate'
											value={moment(initialValues.publishedOnDate).format()}
											displayFormat={'dd/MM/yyyy HH:mm'}
											type='datetime'
											onValueChanged={(e) => {
												setInitialValues((prev) => ({ ...prev, publishedOnDate: e.value }));
											}}
										/>
									</div>

									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='genre'>Loại tin hiển thị</h6>
										</div>
										<ComboBox
											name='genre'
											defaultText='Loại tin'
											value={initialValues.genre}
											dataSource={eArticleGenresData}
											keyExpr='Code'
											displayExpr='Name'
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, genre: value }));
											}}
											sizeClass='sm'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='status'>Trạng thái</h6>
										</div>
										<SwitchBox
											name='isAMP'
											value={initialValues.isAMP}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, isAMP: value }));
											}}
											sizeClass='sm'
											label='AMP'
										/>
										{'   '}
										<SwitchBox
											name='isHiddenContent'
											value={initialValues.isHiddenContent}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, isHiddenContent: value }));
											}}
											sizeClass='sm'
											label='Ẩn nội dung'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='genre'>Vùng hiển thị</h6>
										</div>
										<CheckBoxList
											name='archives'
											selectedValue={[]}
											dataSource={eArticleArchivesData}
											sizeClass='sm'
											displayExpr='Name'
											keyExpr='Code'
											onSelectionChanged={(value: number[]) => {
												// setInitialValues((prev) => ({ ...prev, roles: value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='authorPublish'>Bút danh</h6>
										</div>
										<TextBox
											name='authorPublish'
											value={initialValues.authorPublish}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, authorPublish: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='sourceLink'>Nguồn</h6>
										</div>
										<TextBox
											name='sourceLink'
											value={initialValues.sourceLink}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, sourceLink: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Meta Tile</h6>
										</div>
										<TextBox
											name='metaTitle'
											value={initialValues.metaTitle}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, metaTitle: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaDescription'>Meta Description</h6>
										</div>
										<TextAreaBox
											name='metaDescription'
											value={initialValues.metaDescription}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, metaDescription: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
								</div>
							</div>
						</BaseForm>
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={handleSubmit(onSubmitHandler)}
						isLoading={status === eBaseActionStatus.loading}
						mesageText={`Bạn chắc chắc muôn ${getArticleStatusName(showConfirm.actionStatus)} bài viết này?`}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditPage);
